export default {
    appName: 'Exo Massage',
    nav: {
        home: '首页',
        about: '关于我们',
    },
    footer: {
        termsOfService: '服务条款',
        privacyStatement: '隐私说明'
    },
    home: {
        title: '足不出户享受',
        subtitle: '便捷舒适的推拿服务',
        desc: `{{appName}} 服务于2020年成立，是国内领先的上门推拿O2O服务平台，提供专业、正规、安全、便捷的24小时上门推拿服务。经过5年的沉淀积累，已经有不少消费者选择 {{appName}} 平台，并且拥有了一批忠实稳定的客户。{{appName}} 服务支持客户端等渠道进行预约，足不出户即可享受舒适便捷的上门推拿服务。`,
        ios: 'IOS版',
        android: 'Android版'
    }
}