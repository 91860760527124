import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import '@/assets/styles/reset.css'

import '@/assets/styles/main.css'
import './setRem'
import zh from './assets/langurage/zh';
import {createI18n} from "vue-i18n";

// 组合语言包对象
const messages = {
    zh
}
const i18n = createI18n({
    legacy: false,  // 设置为 false，启用 composition API 模式
    messages,
    locale: 'zh'
})


const app = createApp(App);
app.use(i18n);
app.use(router).mount('#app')
